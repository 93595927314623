import React from 'react'
import { GetStaticProps } from 'next'
import { useRouter } from 'next/router';
import { MenuBar } from '../components/MenuBar'
import { Cookies } from '../components/Cookies'
import { Content } from '../components/Content'
import { Footer } from '../components/Footer'
import { Header, MainSiteHomePageData } from '../lib/strapi/responseTypes'
import { getHeaderQuery, getMainSiteHomePageQuery } from '../lib/strapi/queries'
import { StrapiDynamicBlockZones } from '../lib/strapi/dynamicBlockZones'
import { IS_PRODUCTION } from '../config'
import StrapiZoneMapper from '../components/StrapiDynamicZones/StrapiZoneMapper'

type MainSiteHomePageProps = { pageData: MainSiteHomePageData | null, headerData: Header }

export default function Homepage({ pageData, headerData }: MainSiteHomePageProps) {
  function mapStrapiPageZone(data: Array<StrapiDynamicBlockZones>) {
    return data.map((zone) => (
      <StrapiZoneMapper
        key={zone.__typename}
        dynamicZoneName={zone.__typename}
        {...zone}
      />
    ))
  }

  if (pageData) {
    const headerBannerZone = pageData?.data.attributes.Body.filter(
      (zone) => zone.__typename === 'ComponentMarketingHeaderBanner'
    )
    const bodyWithoutTheHeaderBanner = pageData?.data.attributes.Body.filter(
      (zone) => zone.__typename !== 'ComponentMarketingHeaderBanner'
    )

    const { asPath } = useRouter();

    return (
      <>
        {mapStrapiPageZone(pageData?.data.attributes.Header)}
        <Cookies />
        <MenuBar currentPage={asPath} menuItems={headerData.data.attributes.menuItems} />
        {mapStrapiPageZone(headerBannerZone)}
        <Content>{mapStrapiPageZone(bodyWithoutTheHeaderBanner)}</Content>
        <Footer />
      </>
    )
  }
  return <>404</>
}

export const getStaticProps: GetStaticProps<MainSiteHomePageProps> =
  async () => {
    const page = await getMainSiteHomePageQuery({
      showPreview: !IS_PRODUCTION,
    })

    const headerData = await getHeaderQuery({
      showPreview: !IS_PRODUCTION,
    })

    return {
      props: { pageData: page.wwwHomePage, headerData: headerData.header },
    }
  }
